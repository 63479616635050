import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-consult-button',
  templateUrl: './consult-button.component.html',
  styleUrl: './consult-button.component.scss'
})
export class ConsultButtonComponent {
  @Input() isRecommended: boolean = false;
  @Input() isDelistRecommended: boolean = false;
  @Input() btnDisable: boolean = false;
  @Input() isActive: boolean = false;
  @Input() btnSize: string = '';
  @Input() hostingFrom: string = '';
  @Output() actionBtnCallback = new EventEmitter<any>();

  onBtnClick() {
    this.actionBtnCallback.emit();
  }
}
