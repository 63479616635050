import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MessageService } from "primeng/api";
import { debounceTime } from "rxjs/operators";
import { Version } from "../../main-container/planogram/v2/plano.types";
import { HttpService } from "../../services/http.service";
import { StateService } from "../../services/state.service";

// Component decorator
@Component({
  selector: "app-version-history",
  templateUrl: "./version-history.component.html",
  styleUrls: ["./version-history.component.scss"],
})
export class VersionHistoryComponent implements OnInit {
  @Input() experimentId: any = "";
  @Input() planId: any = "";
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setVersion: EventEmitter<Version> = new EventEmitter<Version>();

  // Component variables
  visible: boolean = true;
  userID: any;
  isLoading: boolean = false;
  expID: any;
  planID: any;
  versions: Version[] = [];
  groupedVersions!: { date: string; versions: Version[]; count: number }[];
  expandedGroups: { [key: string]: boolean } = {};
  userMap = new Map();

  // Constructor with dependency injection
  constructor(
    private stateService: StateService,
    private httpService: HttpService,
    private messageService: MessageService
  ) {}

  // OnInit lifecycle hook
  ngOnInit(): void {
    this.fetchVersionHistory();
  }

  // Method to handle error
  handleError(error: any) {
    if (error.status === 400) {
      this.messageService.add({
        key: "br",
        severity: "warn",
        summary: "Warn Message",
        detail: error.error.message,
      });
    } else if (error.status !== 401) {
      this.messageService.clear();
      this.messageService.add({
        key: "br",
        severity: "warn",
        summary: error.error.message,
        detail: error.error.traceback,
      });
    }
  }

  // Method to fetch the version history
  fetchVersionHistory() {
    let refId = sessionStorage.getItem("refId") || "";
    const url = `${this.stateService.apiList.planogram.versionHistory(
      refId ? refId : this.experimentId,
      this.planId
    )}?page=1&limit=400`;
    this.isLoading = true;
    this.httpService
      .getMethod(url)
      .pipe(debounceTime(500))
      .subscribe(
        (response) => {
          this.isLoading = false;
          response.versions.forEach((v: any) => {
            v.created_at_original = v.created_at;
          });
          console.log(response);
          this.initHistory(response);
        },
        (error) => {
          this.handleError(error);
        }
      );
  }

  // Method to mock the version history
  mockVersionHistory() {
    const url = "assets/version-history.json";
    this.isLoading = true;
    this.httpService
      .getMockFile(url)
      .pipe(debounceTime(500))
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.initHistory(response);
        },
        (error) => {
          this.handleError(error);
        }
      );
  }

  // Method to initialize the version history records
  initHistory(data: any = null) {
    // If data is null, return and do nothing
    if (!data || !data["versions"]) {
      return;
    }

    // Initialize the version array and sort it by created_at date
    this.versions = data["versions"];

    // Update the created_at from UTC to local time zone
    this.versions.forEach((v) => {
      let date = new Date(v.created_at + "Z");
      v.created_at = date.toLocaleString("en-US");
    });

    // If version is present in the query params, mark that version as
    // the current version
    const url = new URL(window.location.href);
    const versionId = url.searchParams.get("version");
    if (versionId) {
      const version = this.versions.find((v) => v.id === versionId);
      if (version) {
        version.current = true;
      }
    } else {
      this.versions[0].current = true;
    }

    // Group the versions by date
    this.groupedVersions = this.groupVersionsByDate(this.versions);

    // Set the expandedGroups to true for all the groups
    this.groupedVersions.forEach((group) => {
      this.expandedGroups[group.date] = true;
    });

    // Fetch the user details for all the created_by users
    const userIds = this.versions.map((v: any) => v.created_by);
    this.getUsersDetail(userIds);
  }

  // Method to group versions by date
  groupVersionsByDate(
    versions: Version[]
  ): { date: string; versions: Version[]; count: number }[] {
    const grouped: { [key: string]: Version[] } = {};

    // Iterate over the versions and group them by date
    versions.forEach((version) => {
      const dateKey = version.created_at.split(",")[0];

      // If the dateKey is not present in the grouped object, create a new array
      if (!grouped[dateKey]) {
        grouped[dateKey] = [];
      }

      // Push the version to the array
      grouped[dateKey].push(version);
    });

    // Return the grouped versions with the count
    return Object.keys(grouped).map((date) => ({
      date: date,
      versions: grouped[date],
      count: grouped[date].length,
    }));
  }

  // Method to toggle the visibility of a group
  toggleGroup(date: string): void {
    this.expandedGroups[date] = !this.expandedGroups[date];
  }

  // Method to fetch the user details in bulk
  getUsersDetail(userIds: string[]) {
    const payload = {
      users_list: userIds,
    };

    this.stateService.getBulkUserDetailsService(payload).subscribe(
      (val) => {
        if (val) {
          // Iterate over val and create a map of user_id to name
          val.forEach((user: any) => {
            this.userMap.set(user.id, user.first_name + " " + user.last_name);
          });
        }
      },
      (err) => {
        this.handleError(err);
      }
    );
  }

  // Method to set the preview version
  setPreviewVersion(version: Version) {
    // Reset the current version for all the versions
    this.resetCurrentVersions();

    // Set the preview version as the current version
    version.current = true;

    // Emit the version id to the parent component
    this.setVersion.emit(version);
    this.closeVersionHistoryPopup();
  }

  // Reset the current version for all the versions
  resetCurrentVersions() {
    this.versions.forEach((v) => {
      v.current = false;
    });
  }

  // Reset the latest version as the current version
  resetCurrentVersionToLatest() {
    this.resetCurrentVersions();
    this.versions[0].current = true;
  }

  // Close the version history popup
  closeVersionHistoryPopup() {
    this.closePopup.emit(true);
  }

  // Return the user readable state name
  getStateName(state: string) {
    const stateMap: { [key: string]: string } = {
      dimension_setup: "Dimension Setup",
      segment_allocation: "Space Allocation & Rules",
      product_allocation: "Product Placement",
    };

    return stateMap[state];
  }

  // Return the icon for the state status
  getStateIcon(state: string) {
    const stateMap: { [key: string]: string } = {
      not_started: "pending",
      in_progress: "donut_large",
      completed: "check_circle",
      failed: "cancel",
    };

    return stateMap[state];
  }

  // Return the class color based on the state status
  getStateColor(state: string) {
    const stateMap: { [key: string]: string } = {
      not_started: "grey",
      in_progress: "blue",
      completed: "green",
      failed: "red",
    };

    return stateMap[state];
  }
}
