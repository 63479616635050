<ng-container *ngIf="type == 1">
  <div *ngFor="let error of errors">
    <span class="error-text pt-1"> {{ error }} </span>
  </div>
</ng-container>

<ng-container *ngIf="type == 2">
  <div *ngFor="let error of errors">
    <p
      class="{{ customClassName }}"
      *ngIf="
        control &&
        control.invalid &&
        (control.dirty || control.touched) &&
        control.hasError(error.key)
      "
    >
      <span class="error-text pt-1"> {{ error && error.message }} </span>
    </p>
  </div>
</ng-container>
<ng-container *ngIf="type == 'import_planogram'">
  <div *ngFor="let error of errors">
    <p
      *ngIf="
        control &&
        control.invalid &&
        (control.dirty || control.touched) &&
        control.hasError(error.key)
      "
    >
      <span class="error-text pt-1" style="font-size: 12px">
        {{ error && error.message }}
      </span>
    </p>
  </div>
</ng-container>
<ng-container *ngIf="type == 'landing'">
  <div *ngFor="let error of errors">
    <p
      class="err_message"
      *ngIf="
        control &&
        control.invalid &&
        (control.dirty || control.touched) &&
        control.hasError(error.key)
      "
    >
      <span class="error-text-landing pt-1">
        {{ error && error.message }}
      </span>
    </p>
  </div>
</ng-container>
<ng-container *ngIf="type == 'template'">
  <div>
    <p *ngIf="errors">
      <span class="error-text pt-1"> {{ errors }} </span>
    </p>
  </div>
</ng-container>
