import { Component } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { SocketService } from "./services/socket.service";
import { StateService } from "./services/state.service";
import { ThemeService } from "./services/theme.service";
import { TitleService } from "./services/title.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [MessageService],
})
export class AppComponent {
  title = "catMan-ui";
  result: any;
  toastMarginClass: "right-toolbar-open" | "right-toolbar-close" =
    "right-toolbar-open";

  constructor(
    public stateService: StateService,
    private socketService: SocketService,
    private route: ActivatedRoute,
    private router: Router,
    public messageService: MessageService,
    public translate: TranslateService,
    private titleService: TitleService,
    public themeService: ThemeService
  ) {
    //translation

    translate.addLangs(["en", "pt"]);
    translate.setDefaultLang("en");

    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

    var mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    if (!mac) {
      window.document.body.id = "custom-scrollbar";
    }

    this.route.queryParams.subscribe((params: any) => {
      if (params.access_token && params.refresh_token) {
        window.localStorage.c = params.access_token;
        window.localStorage.d = params.refresh_token;
        window.localStorage.i = params.id;
        // This is the first time here we set the tokenExpiryDate
        this.stateService.setTokenExpiryDate();

        let redirectURL =
          sessionStorage.getItem("callbackURL") || this.router.url;

        var currentUrl =
          this.stateService.removeQueryParamsFromUrl(redirectURL);
        this.router.navigate([currentUrl], {
          replaceUrl: true,
        });
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.titleService.setDefaultTitle();
        this.titleService.setFavicon();
      }
    });
    this.openToast();
    this.openSoftWarningToast();
  }

  ngOnDestroy() {
    this.socketService.disconnect();
  }
  closeDownloadToast() {
    this.stateService.isDownload = false;
    this.messageService.clear("downloadToast");
  }
  openToast() {
    this.stateService.Result.subscribe((data: any) => {
      this.messageService.add({
        key: "downloadToast",
        severity: "info",
        summary: "Warn Message",
        sticky: true,
        closable: false,
      });
    });
  }
  openSoftWarningToast() {
    this.stateService.openSoftWarningToast.subscribe((data: any) => {
      if (data) {
        this.toastMarginClass = data["marginClass"];
        this.messageService.add({
          key: "softWarningToast",
          severity: "info",
          summary: "Warn Message",
          detail: data["message"],
          life: 5000,
          closable: false,
        });
      }
    });
  }
  closeSoftWarningToast() {
    this.messageService.clear("softWarningToast");
  }
}
