import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MessageService } from "primeng/api";
import { HttpClient, HttpParams } from "@angular/common/http";
import { StateService } from "../../services/state.service";
import { HttpService } from "../../services/http.service";
import { Subscription } from "rxjs";
@Component({
  selector: "app-toast-download",
  templateUrl: "./toast-download.component.html",
  styleUrls: ["./toast-download.component.scss"],
  providers: [MessageService],
})
export class ToastDownloadComponent implements OnInit {
  showDownload: Boolean = false;
  startLoader = false;
  value: number = 0;
  fileName = "Loading";
  fileDownloaded: Boolean = false;
  result: any = {};
  @Output() closeIconClicked = new EventEmitter<string>();
  loading = true;
  downloadFailed: Boolean = false;
  expId: any;
  selectedCluster!: string;
  receivedData: any;
  progressBarMode:string ="determinate";

  assortmentSpace: string = "/space-integration/assortment";
  planogramSpace: string = "/space-integration/planogram";
  fileFrom: string = "";
  constructor(
    private messageService: MessageService,
    private http: HttpClient,
    public stateService: StateService,
    private httpService: HttpService
  ) {}

  ngOnInit() {
    this.showDownload = true;
    this.getProgress();
    this.expId = sessionStorage.getItem("expId");
    this.stateService.setFileName.subscribe((data) => {
      if (data) {
        this.selectedCluster = data;
      }
    });

    this.stateService.data$.subscribe((data: any) => {
      this.receivedData = data;
    });
  }
  getProgress() {
    this.stateService.downloadProgressForSimulation.subscribe((data: any) => {
      // for planogram export excel,pdf, psa,pln
      if (data.type === "planogram-download") {
        switch (data.status) {
          case "in_progress":
            this.fileFrom = "planogram";
            this.progressBarMode = "indeterminate";
            this.fileDownloaded = false;
            // this.value += Math.floor(Math.random() * 10) + 1;
            break;

          case "completed":
            this.value = 100;
            this.fileDownloaded = true;
            this.fileName = data.fileName;
            break;

          case "failed":
            this.downloadFailed = true;
            break;
          default:
            console.log("File Error");
        }
        return;
      }
      if (
        data === "loading" ||
        data?.status === "STARTED" ||
        data?.status === "RETRY"
      ) {
        this.fileDownloaded = false;
        this.value = this.value + Math.floor(Math.random() * 10) + 1;
      } else if (
        data.status === "SUCCESS" ||
        data?.status === this.stateService.status[3]
      ) {
        if (data.task_name === "download-assortment-report") {
          this.fileName = "Assortment Summary";
        } else if (data.title === "assortmentSpaceSuccess") {
          this.fileName = "Space_Assortment_Report";
        } else if (data.title === "planogramSpaceSuccess") {
          this.fileName = "Space_Planogram_Report";
        } else if (data.title === "DownloadExperiment") {
          this.fileName = "Download data";
        } else {
          this.fileName = "Financial Report";
        }
        this.value = 100;
        this.fileDownloaded = true;
        this.result = data;
      } else if (data?.status === "FAILURE") {
        this.downloadFailed = true;
      }
    });
  }

  downloadFile() {
    if (this.fileFrom == "planogram") {
      this.stateService.triggerDownloadService();
      this.closeIconClicked.emit();
      return;
    }
    if (this.fileName === "Space_Assortment_Report") {
      this.getAssortmentSpaceResponseStream();
      this.getAssortmentSpaceBusinessResponseStream();
      this.closeDownload();
    } else if (this.fileName === "Space_Planogram_Report") {
      this.getPlanogramSpaceResponseStream();
      this.getPlanogramSpaceBusinessResponseStream();
      this.closeDownload();
    } else if (this.fileName === "Download data") {
      this.getExpDownload();
      //this.closeDownload();
    } else {
      this.showDownload = true;
      this.startLoader = true;
      let fileURL = "";
      if (this.result.result.result.filepath) {
        fileURL = this.result.result.result.filepath;
      } else {
        fileURL = this.result.result.result.results.filepath;
      }

      let downloadUrl = `${this.stateService.apiList.experiments.download}?filepath=${fileURL}`;
      this.http.get(downloadUrl, { responseType: "arraybuffer" }).subscribe(
        (val) => {
          if (val) {
            const blob: Blob = new Blob([val], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
            });
            let hiddenElement = document.createElement("a");
            hiddenElement.href = URL.createObjectURL(blob);
            hiddenElement.target = "_blank";
            let items = fileURL.split("/").slice(-1)[0].split(".");
            let filename = items[0];
            let extension = items[1];
            hiddenElement.download = this.stateService.getFileName(
              filename,
              extension
            );
            this.startLoader = false;
            hiddenElement.click();
            this.closeDownload();
          }
        },
        (err) => {
          // TODO - Add error toast here
        }
      );
    }
  }
  setShowDownload(value: Boolean) {
    this.showDownload = value;
  }
  closeDownload() {
    if (this.stateService.individualProcess) {
      this.stateService.individualProcess.unsubscribe();
    }
    this.closeIconClicked.emit();
  }

  getAssortmentSpaceResponseStream() {
    // const params = new HttpParams().set('output_type', outputType);
    let outputType = "plano_optimization";
    const url = `${this.stateService.apiList.experiments.assortment}${this.expId}${this.assortmentSpace}/download?output_type=${outputType}`;
    let isDownLoad = true;

    this.httpService.getBlobMethod(url).subscribe((val: any) => {
      if (val) {
        let blob = new Blob([val], {
          type: "text/csv",
        });

        const download = `Space_optimized_${
          this.selectedCluster
        }_plano_input_${this.getFormattedTimestamp()}.csv`;
        this.downloadResult(download, blob);
      }
    });
  }

  getAssortmentSpaceBusinessResponseStream() {
    const outputType = "business";
    const url = `${this.stateService.apiList.experiments.assortment}${this.expId}${this.assortmentSpace}/download?output_type=${outputType}`;
    let isDownLoad = true;

    this.httpService.getBlobMethod(url).subscribe((val: any) => {
      if (val) {
        let blob = new Blob([val], {
          type: "application/vnd.ms-excel",
        });

        const download = `Space_optimized_${
          this.selectedCluster
        }_business_output_${this.getFormattedTimestamp()}.xlsx`;
        this.downloadResult(download, blob);
      }
    });
  }

  getPlanogramSpaceResponseStream() {
    let outputType = "plano_optimization";
    const url = `${this.stateService.apiList.experiments.assortment}${this.expId}${this.planogramSpace}/download?output_type=${outputType}`;
    this.httpService.getBlobMethod(url).subscribe((val: any) => {
      if (val) {
        let blob = new Blob([val], {
          type: "text/csv",
        });

        const download = `Space_optimized_${
          this.selectedCluster
        }_plano_input_${this.getFormattedTimestamp()}.csv`;
        this.downloadResult(download, blob);
      }
    });
  }

  getPlanogramSpaceBusinessResponseStream() {
    const outputType = "business";
    const url = `${this.stateService.apiList.experiments.assortment}${this.expId}${this.planogramSpace}/download?output_type=${outputType}`;
    this.httpService.getBlobMethod(url).subscribe((val: any) => {
      if (val) {
        let blob = new Blob([val], {
          type: "application/vnd.ms-excel",
        });

        let download = `Space_optimized_${
          this.selectedCluster
        }_business_output_${this.getFormattedTimestamp()}.xlsx`;
        this.downloadResult(download, blob);
      }
    });
  }

  getExpDownload() {
    let expData = this.receivedData;
    this.startLoader = true;
    const url = `${this.stateService.apiList.experiments.getExperimentsV3}/${expData?.id}/download`;
    this.httpService.getBlobMethod(url).subscribe((val: any) => {
      if (val) {
        let blob = new Blob([val], {
          type: "application/zip",
        });
        const download = `${expData?.name}_${expData?.id}.zip`;
        this.downloadResult(download, blob);
        this.closeDownload();
      }
    });
  }

  // Helper function for downlad the experiment
  downloadResult(downloadKey: string, blob: any) {
    let hiddenElement = document.createElement("a");
    hiddenElement.href = URL.createObjectURL(blob);
    hiddenElement.target = "_blank";
    hiddenElement.download = downloadKey;
    hiddenElement.click();
  }

  private getFormattedTimestamp(): string {
    const date = new Date();
    return `${date.getFullYear()}_${this.padZero(
      date.getMonth() + 1
    )}_${this.padZero(date.getDate())}`;
  }

  private padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
