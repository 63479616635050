<div
  class="container popup-class dark"
  [ngClass]="{ 'maximize-popup': showDownload == true }"
>
  <div
    class="processing-row"
    [ngClass]="{ 'download-header': showDownload == true }"
  >
    <div *ngIf="showDownload == true">
      <button class="refresh-button">
        <i class="pi pi-sync" style="font-size: 15px"></i>
      </button>
    </div>
    <div class="header-label" *ngIf="showDownload == true">
      <label class="processing-label">Processing Queue</label>
    </div>
    <div>
      <button
        class="arrow-button"
        *ngIf="showDownload == true"
        (click)="setShowDownload(false)"
      >
        <i class="pi pi-angle-down" style="font-size: 18px"></i>
      </button>
      <button
        class="arrow-button"
        *ngIf="showDownload == false"
        (click)="setShowDownload(true)"
      >
        <i class="pi pi-angle-up" style="font-size: 18px"></i>
      </button>
    </div>
    <button class="close-button" (click)="closeDownload()">
      <i class="pi pi-times" style="font-size: 14px"></i>
    </button>
  </div>
  <div class="files" *ngIf="showDownload == true">
    <div
      class="file-card"
      *ngIf="!downloadFailed"
      [ngStyle]="{ 'background-color': fileDownloaded ? '#f2f2f2' : '#fff' }"
    >
      <ng-container>
        <div class="file-body">
          <button class="file-icon">
            <i
              class="pi pi-file-excel"
              style="font-size: 30px"
              *ngIf="!fileDownloaded"
            ></i>
            <i
              class="pi pi-file"
              style="font-size: 30px"
              *ngIf="fileDownloaded"
            ></i>
          </button>
          <div class="file-name">
            {{ fileName }}
          </div>
          <div>
            <button
              class="cancel-button"
              *ngIf="!fileDownloaded && !downloadFailed"
              (click)="closeDownload()"
            >
              Cancel
            </button>
            <button
              *ngIf="fileDownloaded"
              (click)="downloadFile()"
              class="done-button"
            >
              <span
                *ngIf="startLoader"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <i *ngIf="!startLoader" class="pi pi-download"></i>
            </button>
          </div>
        </div>
        <div class="file-progressbar" *ngIf="!fileDownloaded">
          <p-progressBar
            class="progress-download"
            [value]="value"
            [mode]="progressBarMode"
          ></p-progressBar>
        </div>
      </ng-container>
    </div>
    <div class="file-card" *ngIf="downloadFailed">
      <ng-container>
        <div class="download-failed">
          <button class="download-failed-button" style="align-self: start">
            <i class="pi pi-exclamation-circle" style="font-size: 20px"></i>
          </button>
          <div class="download-failed-button-file-name">
            <div class="failed-filename">{{ fileName }}</div>
            <span style="font-size: 14px"
              >Something went wrong during the process</span
            >
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
