<div class="middle-content mt-1 px-2">
  <div class="box h-[145px] items-center justify-center">
    <div class="w-full flex-col">
      <div
      class="icon-box rounded-full flex items-center justify-center "
      [ngStyle]="{ 'background-color': content?.color_code }"
    >
      <img src="/assets/images/{{ content.icon }}" />
    </div>
      <div class="title-text">{{ content.title }}</div>
      <div class="value-text pb-1">{{ content.value }}</div>
    </div>
  </div>
</div>
