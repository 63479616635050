export const environment = {
  production: false,
  apiUrl: "https://dev.api.catexpert.ab-inbev.com/",
  baseUrlV2: "https://dev.api.catexpert.ab-inbev.com/projects/api/v2",
  baseUrlV3: "https://dev.api.catexpert.ab-inbev.com/projects/api/v3",
  baseUrl: "https://dev.api.catexpert.ab-inbev.com/projects/api/",
  basePlanoUrl: "https://dev.api.catexpert.ab-inbev.com/planogram/",
  url: "https://dev.api.catexpert.ab-inbev.com/reference/api/v1/",
  edaurl: "https://dev.api.catexpert.ab-inbev.com/eda/api/v1/",
  edaurlV2: "https://dev.api.catexpert.ab-inbev.com/assortment/api/v1/",
  expurl: "https://dev.api.catexpert.ab-inbev.com/process/api/v1/",
  simulationurl: "https://dev.api.catexpert.ab-inbev.com/simulation/api/v1/",
  userUrl: "https://dev.api.catexpert.ab-inbev.com/user/api/v1/",
  userUrlV3: "https://dev.api.catexpert.ab-inbev.com/user/api/v3/",
  phoBaseUrl: "https://dev.api.catexpert.ab-inbev.com/pho/api/v1",
  socketBaseUrl: "http://localhost:3000",
  socketCommonPath: "/api/v1/",
  imageUrl: `https://cdn.catexpert.ab-inbev.com/planogram/`,
  powerBIDasboardURL:
    "https://app.powerbi.com/links/YO2x5XKUhQ?ctid=cef04b19-7776-4a94-b89b-375c77a8f936&pbi_source=linkShare&bookmarkGuid=352c22c7-25b4-46db-86a6-6fe63f874a68",
  assortmentUrl: `https://dev.api.catexpert.ab-inbev.com/assortment/api/v1/`,
  goProcess: `https://dev.api.catexpert.ab-inbev.com/go-process/tasks/`,
  goProcessStates: `https://dev.api.catexpert.ab-inbev.com/go-process/refs/`,
  planogramWebsocketUrl: "wss://dev.api.catexpert.ab-inbev.com/ws/planogram",
};
