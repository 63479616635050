import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private darkTheme = "dark";
  private lightTheme = "light";

  constructor() {
    this.loadTheme(); // Load the theme from localStorage on app start
  }

  toggleDarkMode(): void {
    const htmlElement = document.documentElement;
    if (htmlElement.classList.contains(this.darkTheme)) {
      this.setLightMode();
    } else {
      this.setDarkMode();
    }
  }

  setDarkMode(): void {
    document.documentElement.classList.add(this.darkTheme);
    localStorage.setItem("theme", this.darkTheme); // Save preference
  }

  setLightMode(): void {
    document.documentElement.classList.remove(this.darkTheme);
    localStorage.setItem("theme", this.lightTheme); // Save preference
  }

  loadTheme(): void {
    console.log('called')
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === this.darkTheme) {
      this.setDarkMode();
    } else {
      this.setLightMode();
    }
  }
}
