import { Injectable } from "@angular/core";
import { getMatFormFieldMissingControlError } from "@angular/material/form-field";
import { StateService } from "./../services/state.service";
import Highcharts, { each } from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsFunnel from "highcharts/modules/funnel";
import HighchartsPareto from "highcharts/modules/pareto";
import { toTitleCase } from "../utils";
HighchartsMore(Highcharts);
HighchartsFunnel(Highcharts);
HighchartsPareto(Highcharts);

@Injectable({
  providedIn: "root",
})
export class CreateChartService {
  constructor(private stateService: StateService) {}

  toTitleCase(str: string): string {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  nParseFloat(val: any) {
    if (typeof val == "string") {
      return val != "" ? val : "-";
    } else if (this.isInt(val)) {
      return this.formatNumber(val);
    } else if (val && typeof val != "string" && !this.isInt(val)) {
      const floatVal: any = this.stateService.nParseFloat(val);
      return this.formatNumber(floatVal);
    } else {
      return val || "-";
    }
  }

  isInt(num: any) {
    return Number(num) === num && num % 1 === 0;
  }

  formatNumber(num: number) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  setChartData(
    edaData: any = [],
    x: any,
    y: any,
    type: any,
    bgColor: any,
    options?: any
  ) {
    const labels = edaData.map(function (el: any) {
      return el[x];
    });
    const values = edaData.map(function (el: any) {
      return type === "pie" ? el[y] : el[y]?.toFixed();
    });
    let data: any;
    if (type === "pie") {
      data = {
        labels: labels,
        datasets: [
          {
            // type: type,
            data: values,
            backgroundColor:
              bgColor.length > 0
                ? bgColor
                : [
                    "#D65623",
                    "#F9DFB0",
                    "#F49C00",
                    "#A63132",
                    "#a09ae6",
                    "#de425b",
                    "#488f31",
                  ],
            hoverBackgroundColor: ["#FFE082", "#81D4FA", "#A5D6A7"],
          },
        ],
      };
    } else if (type === "bar") {
      data = {
        labels: labels,
        datasets: [
          {
            // type: type,
            data: values,
            tension: 0.4,
            backgroundColor: "#49C2CA",
            borderColor: "#49C2CA",
          },
        ],
      };
    } else if (type === "stacked_bar") {
      data = {
        labels: labels,
        datasets: [
          {
            label: "ABI",
            backgroundColor: "#325A6D",
            borderColor: "#325A6D",
            data: [],
          },
          {
            label: "NON ABI",
            backgroundColor: "#7ED4D9",
            borderColor: "#7ED4D9",
            data: [],
          },
        ],
      };
    } else {
      data = {
        labels: labels,
        datasets: [
          {
            label:
              sessionStorage.getItem("expType") === "pho"
                ? y.toUpperCase()
                : y === "total_volume"
                  ? "Volume"
                  : "Revenue",
            data: values,
            // fill: false,
            backgroundColor: options
              ? this.createLinearGradient(0, 0, 0, 200, [
                  { offset: 0, color: "rgba(229, 182, 17, 1)" },
                  { offset: 1, color: "rgba(229, 182, 17, 0)" },
                ])
              : y === "total_revenue"
                ? this.createLinearGradient(0, 0, 0, 200, [
                    { offset: 0, color: "rgba(229, 182, 17, 1)" },
                    { offset: 1, color: "rgba(229, 182, 17, 0)" },
                  ])
                : y === "total_volume"
                  ? this.createLinearGradient(0, 0, 0, 200, [
                      { offset: 0, color: "rgba(50, 90, 109, 1)" },
                      { offset: 1, color: "rgba(255, 255, 255, 0)" },
                    ])
                  : "#E5B611",
            fill: options
              ? options.fill
              : sessionStorage.getItem("expType") === "pho"
                ? false
                : true,
            borderColor:
              y === "total_revenue"
                ? "#E5B611"
                : y === "total_volume"
                  ? "#325A6D"
                  : "#E5B611",
            borderWidth: 1,
            tension: 0.4,
          },
        ],
      };
    }
    return data;
  }

  generateColorShades(baseColor: string, count: number): string[] {
    const shades = [];
    const [r, g, b] = this.hexToRgb(baseColor);

    for (let i = 0; i < count; i++) {
      const factor = i / (count - 1); // Normalize factor between 0 and 1
      const shade = this.rgbToHex(
        Math.min(255, Math.max(0, Math.round(r + factor * (255 - r)))), // Adjust red
        Math.min(255, Math.max(0, Math.round(g + factor * (255 - g)))), // Adjust green
        Math.min(255, Math.max(0, Math.round(b + factor * (255 - b)))) // Adjust blue
      );
      shades.push(shade);
    }
    return shades;
  }

  // Convert hex to RGB
  hexToRgb(hex: string): [number, number, number] {
    hex = hex.replace(/^#/, "");

    // Parse the r, g, b values from the hex string using slice
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    return [r, g, b];
  }

  // Convert RGB to hex
  rgbToHex(r: number, g: number, b: number): string {
    return `#${[r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("")}`;
  }

  createLinearGradient(
    x0: number,
    y0: number,
    x1: number,
    y1: number,
    colorStops: any[]
  ) {
    const ctx: any = document.createElement("canvas").getContext("2d");
    const gradient = ctx.createLinearGradient(x0, y0, x1, y1);
    colorStops.forEach((stop) =>
      gradient.addColorStop(stop.offset, stop.color)
    );
    return gradient;
  }

  setCompareChart(edaData: any = []) {
    let data: any;
    const labels = Object.keys(edaData);
    data = {
      labels: labels,
      datasets: [
        {
          label: "ABI",
          backgroundColor: "#325A6D",
          borderColor: "#325A6D",
          data: [],
        },
        {
          label: "NON ABI",
          backgroundColor: "#7ED4D9",
          borderColor: "#7ED4D9",
          data: [],
        },
      ],
    };
    labels.forEach((element) => {
      data.datasets[0].data.push(
        edaData[`${element}`]?.ABI?.revenue_contribution || 0
      );
      data.datasets[1].data.push(
        edaData[`${element}`]?.NON_ABI?.revenue_contribution || 0
      );
    });
    return data;
  }

  // Function for dynamic nesting walkway rates chart
  setLineBarCompareChart(
    densityCurve: any = [],
    walkAwayRate: any = [],
    x: any,
    y: any,
    type: any
  ) {
    let data: any;

    data = {
      labels: walkAwayRate.map((item: any) => item[x]),
      datasets: [
        {
          type: "line",
          label: "",
          borderColor: "#EE8C45",
          borderWidth: 2,
          fill: false,
          yAxisID: "y1",
          xAxisID: "x1",
          tension: 0.4,
          data: densityCurve.map((item: any) => item.density),
          pointRadius: 0, // Hide the circles
          pointHoverRadius: 0, // Hide the hover effect circles
        },
        {
          type: "bar",
          label: "",
          backgroundColor: "#684088",
          data: walkAwayRate.map((item: any) => item[y]),
          borderColor: "white",
          borderWidth: 1,
          barPercentage: 1,
          yAxisID: "y",
          categoryPercentage: 1,
        },
      ],
    };

    return data;
  }
  setRecomCompareChart(edaData: any = [], labels: any) {
    let data: any;
    let lbls: any;
    const size = edaData.length;
    lbls = [];
    edaData.forEach((element: any) => {
      lbls.push(element.cluster_name);
    });
    data = {
      labels: lbls,
      datasets: [
        {
          label: "",
          backgroundColor: "#7FD4B7",
          borderColor: "#7FD4B7",
          data: [],
          barPercentage: 1,
          borderWidth: 0,
          //  categoryPercentage: 0.5
        },
        {
          label: "",
          backgroundColor: "#706ACA",
          borderColor: "#706ACA",
          data: [],
          barPercentage: 1,
          borderWidth: 0,
          //   categoryPercentage: 0.5
        },
      ],
    };

    edaData.forEach((ele: any, j: any) => {
      labels.forEach((element: any, i: any) => {
        data.datasets[i].data.push(+ele[`${element}`]?.replace("%", ""));
      });
    });
    return data;
  }
  setSimCompareChart(edaData: any = [], labl: any, isPercentage: boolean) {
    if (edaData.length === 1) {
      let data: any;
      let labels: any;
      labels = [];
      let temp: any;
      temp = [];
      for (let index = 0; index < 3; index++) {
        temp.push(edaData[0].cluster_name);
      }

      data = {
        labels: labels,
        datasets: this.formDatas(temp),
        graphdata: [],
      };
      data.datasets.forEach((element: any) => {
        data.graphdata.push({
          cluster_name: element.clustername,
          clusterColor: element.backgroundColor,
        });
      });
      let labls = labl;
      data.labels = data.graphdata.map(function (el: any) {
        return el.cluster_name;
      });
      labls.forEach(function (value: any, j: any) {
        edaData.forEach((element: any, i: string | number) => {
          const tem = isPercentage
            ? element[`${value}`].replace("%", "")
            : element[`${value}`];
          data.datasets[j].data[j] = +tem || 0;
        });
      });
      return data;
    } else {
      let data: any;
      let labels: any;
      labels = [];
      let temp: any;
      temp = [];
      edaData.forEach((element: any) => {
        temp.push(element.cluster_name);
      });
      data = {
        labels: ["", "", ""],
        datasets: this.formData(temp),
        graphdata: [],
      };
      data.datasets.forEach((element: any) => {
        data.graphdata.push({
          cluster_name: element.clustername,
          clusterColor: element.backgroundColor,
        });
      });
      let labls = labl;

      labls.forEach(function (value: any) {
        edaData.forEach((element: any, i: string | number) => {
          const tem = isPercentage
            ? element[`${value}`].replace("%", "")
            : element[`${value}`];
          data.datasets[i].data.push(+tem || 0);
        });
      });

      return data;
    }
  }
  setSimCompareChartTop3(edaData: any = [], labl: any, isPercentage: boolean) {
    if (edaData.length === 1) {
      let data: any;
      let labels: any;
      labels = [];
      let temp: any;
      temp = [];
      labl = [...new Set(labl)];
      for (let index = 0; index < labl.length; index++) {
        temp.push(edaData[0].cluster_name);
      }

      data = {
        labels: labels,
        datasets: this.formDatas(temp),
        graphdata: [],
      };
      data.datasets.forEach((element: any) => {
        data.graphdata.push({
          cluster_name: element.clustername,
          clusterColor: element.backgroundColor,
        });
      });
      let labls = [...new Set(labl)];
      data.labels = data.graphdata.map(function (el: any) {
        return el.cluster_name;
      });
      labls.forEach(function (value: any, j: any) {
        edaData.forEach((element: any, i: string | number) => {
          if (element[`${value}`] !== undefined) {
            const tem = isPercentage
              ? element[`${value}`].replace("%", "")
              : element[`${value}`];
            data.datasets[j].data[j] = +tem || 0;
          } else {
            data.datasets[j].data[j] = 0;
          }
        });
      });
      return data;
    } else {
      let data: any;
      let labels: any;
      labels = [];
      let temp: any;
      temp = [];
      edaData.forEach((element: any) => {
        temp.push(element.cluster_name);
      });
      data = {
        labels: labels,
        datasets: this.formData(temp),
        graphdata: [],
      };
      data.datasets.forEach((element: any) => {
        data.graphdata.push({
          cluster_name: element.clustername,
          clusterColor: element.backgroundColor,
        });
      });
      let labls = [...new Set(labl)];
      data.labels = data.graphdata.map(function (el: any) {
        return el.cluster_name;
      });
      labls.forEach(function (value: any) {
        edaData.forEach((element: any, i: string | number) => {
          if (element[`${value}`] !== undefined) {
            const tem = isPercentage
              ? element[`${value}`].replace("%", "")
              : element[`${value}`];
            data.datasets[i].data[i] = +tem || 0;
          } else {
            data.datasets[i].data[i] = 0;
          }
        });
      });
      return data;
    }
  }
  formData(temp: any) {
    let tem: any;
    tem = [];
    temp.forEach((element: any, i: any) => {
      tem.push({
        label: "",
        backgroundColor: this.getAnyOne(i),
        borderColor: "#1E88E5",
        data: [],
        barPercentage: 1,
        borderWidth: 0,
        clustername: element,
        //categoryPercentage: 0.5
      });
    });
    return tem;
  }
  formDatas(temp: any) {
    let tem: any;
    tem = [];
    temp.forEach((element: any, i: any) => {
      tem.push({
        label: "",
        backgroundColor: this.getAnyOne(i),
        borderColor: "#1E88E5",
        data: [],
        barPercentage: 1,
        // categoryPercentage: 0.6,
        borderWidth: 0,
        clustername: element,
        // categoryPercentage: 1
      });
    });
    return tem;
  }
  setLineCompareChart(edaData: any = [], x: any, y: any) {
    let data: any;
    const labels = edaData[0].map(function (el: any) {
      return el[x];
    });
    const uniqlabels = [...new Set(labels)];
    let years: any[];
    years = [];
    edaData.forEach((element: any) => {
      years.push(element[0].year);
    });
    const uniq = [...new Set(years)];
    data = {
      labels: uniqlabels,
      datasets: [],
    };
    edaData.forEach((element: any, i: any) => {
      const values = element.map(function (el: any) {
        return el[y] === 0 ? null : el[y];
      });

      const val = values.map(function (el: any) {
        return el === undefined ? null : el;
      });

      const obj = {
        label: uniq[i],
        backgroundColor: this.getLineChartColor(i),
        borderColor: this.getLineChartColor(i),
        borderWidth: 0.8,
        data: val,
      };
      data.datasets.push(obj);
    });

    //  }
    return data;
  }

  getLineChartColor(index: number) {
    return [
      "#FCC7C3",
      "#D63B2F",
      "#FAA19B",
      "#F88279",
      "#F66257",
      "#F44336",
      "#B73229",
      "#992A22",
      "#7A221B",
    ][index];
  }

  getAnyOne(index: number) {
    //new design colours
    return ["#C7972A", "#F7D385", "#FDC039", "#F7E6AB", "#EED065", "#E5B629"][
      index
    ];
  }

  getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  createWaterFallChart(
    title: string,
    data: any[],
    toolTip: any,
    type: any,
    xAxisName: string
  ) {
    let newData = [];
    let label: any = [];
    if (data[0][type] === "total") {
      const totalObj = data.shift();
      const withoutTotalData = data;
      newData = withoutTotalData;
      newData.push(totalObj);
    } else {
      newData = data;
    }
    newData.forEach((element: any) => {
      label.push(element[type]);
    });
    const option = {
      title: {
        text: "",
        subtext: "",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params: any) {
          var tar = params[1];
          var value = tar.value;
          let result;
          if (Math.abs(+value) >= 1000000000) {
            result =
              (+value / 1000000000).toFixed(1).replace(/\.0$/, "") + " B";
          } else if (Math.abs(+value) >= 1000000) {
            result = (+value / 1000000).toFixed(1).replace(/\.0$/, "") + " M";
          } else if (Math.abs(+value) >= 1000) {
            result = (+value / 1000).toFixed(1).replace(/\.0$/, "") + " K";
          } else {
            result = value;
          }
          if (title === "Volume") {
            return tar.name + "<br/>" + tar.seriesName + " : " + result;
          } else {
            return tar.name + "<br/>" + tar.seriesName + " : " + "$" + result;
          }
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        name: xAxisName,
        nameLocation: "middle",
        nameGap:
          xAxisName === "PTC SEGMENT"
            ? 60
            : xAxisName === "MANUFACTURER"
              ? 40
              : 30,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          rotate: 30,
        },
        splitLine: { show: false },
        data: label,
      },
      yAxis: [
        {
          type: "value",
          name:
            title === "Volume" ? "Volume contribution" : "Revenue Contribution",
          nameLocation: "middle",
          nameGap: title === "Volume" ? 40 : 54,
          // min: 0,
          // max: 25,
          position: "left",
          axisLabel: {
            formatter: function (value: any) {
              let result;
              if (Math.abs(+value) >= 1000000000) {
                result =
                  (+value / 1000000000).toFixed(1).replace(/\.0$/, "") + " B";
              } else if (Math.abs(+value) >= 1000000) {
                result =
                  (+value / 1000000).toFixed(1).replace(/\.0$/, "") + " M";
              } else if (Math.abs(+value) >= 1000) {
                result = (+value / 1000).toFixed(1).replace(/\.0$/, "") + " K";
              } else {
                result = value;
              }
              if (title === "Volume") {
                return result;
              } else {
                return "$" + result;
              }
            },
          },
        },
      ],
      series: [
        {
          name: "Placeholder",
          type: "bar",
          stack: "Total",
          itemStyle: {
            borderColor: "transparent",
            color: "transparent",
          },
          emphasis: {
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
          },
          data: newData.map((a: { start_point: any }) => a.start_point),
        },
        {
          name: toolTip,
          type: "bar",
          stack: "Total",
          label: {
            show: false,
            position: "inside",
            formatter: function (d: any) {
              if (Math.abs(+d.data) >= 1000000000) {
                return (
                  (+d.data / 1000000000).toFixed().replace(/\.0$/, "") + " B"
                );
              }
              if (Math.abs(+d.data) >= 1000000) {
                return (+d.data / 1000000).toFixed().replace(/\.0$/, "") + " M";
              }
              if (Math.abs(+d.data) >= 1000) {
                return (+d.data / 1000).toFixed().replace(/\.0$/, "") + " K";
              }
              return d.data;
            },
          },
          color: "#ec7063",
          data: newData.map((a: { add_points: any }) => a.add_points),
        },
      ],
    };
    return option;
  }
  createSimulationWaterFallChart(
    title: string,
    data: any[],
    toolTip: any,
    type: any
  ) {
    let newData = [];
    let label: any = [];
    if (data[0] !== undefined && data[0].label === "total") {
      const totalObj = data.shift();
      const withoutTotalData = data;
      newData = withoutTotalData;
      newData.push(totalObj);
    } else {
      newData = data;
    }
    newData.forEach((element: any) => {
      label.push(element.label);
    });
    const option = {
      title: {
        text: "",
        subtext: "",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params: any) {
          var tar = params[1];
          var value = tar.value;
          let result;
          if (Math.abs(+value) >= 1000000000) {
            result =
              (+value / 1000000000).toFixed(1).replace(/\.0$/, "") + " B";
          } else if (Math.abs(+value) >= 1000000) {
            result = (+value / 1000000).toFixed(1).replace(/\.0$/, "") + " M";
          } else if (Math.abs(+value) >= 1000) {
            result = (+value / 1000).toFixed(1).replace(/\.0$/, "") + " K";
          } else {
            result = value;
          }
          if (toolTip === "VOLUME" || toolTip === "SKU") {
            return tar.name + "<br/>" + result;
          } else {
            return tar.name + "<br/>" + "$" + result;
          }
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        name: "",
        nameLocation: "middle",
        nameGap: type === "Parent_SKU" ? 60 : 40,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          rotate: 30,
          width: "90",
          overflow: "truncate", // or 'break' to continue in a new line
          interval: 0,
        },
        splitLine: { show: false },
        data: label,
      },
      yAxis: [
        {
          type: "value",
          name: "",
          // min: 0,
          // max: 25,
          //  position: 'left',
          nameLocation: "middle",
          nameGap: 40,
          axisLabel: {
            formatter: function (value: any) {
              let result;
              if (Math.abs(+value) >= 1000000000) {
                result =
                  (+value / 1000000000).toFixed(1).replace(/\.0$/, "") + " B";
              } else if (Math.abs(+value) >= 1000000) {
                result =
                  (+value / 1000000).toFixed(1).replace(/\.0$/, "") + " M";
              } else if (Math.abs(+value) >= 1000) {
                result = (+value / 1000).toFixed(1).replace(/\.0$/, "") + " K";
              } else {
                result = value;
              }
              if (toolTip === "VOLUME" || toolTip === "SKU") {
                return result;
              } else {
                return "$" + result;
              }
            },
          },
        },
      ],
      series: [
        {
          name: "Placeholder",
          type: "bar",
          stack: "Total",
          itemStyle: {
            borderColor: "transparent",
            color: "transparent",
          },
          emphasis: {
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
          },
          data: newData.map((a: { start_point: any }) => a.start_point),
        },
        {
          name: toolTip,
          type: "bar",
          stack: "Total",
          label: {
            show: false,
            position: "top",
            formatter: function (d: any) {
              if (Math.abs(+d.data.value) >= 1000000000) {
                return (
                  (+d.data.value / 1000000000).toFixed().replace(/\.0$/, "") +
                  " B"
                );
              }
              if (Math.abs(+d.data.value) >= 1000000) {
                return (
                  (+d.data.value / 1000000).toFixed().replace(/\.0$/, "") + " M"
                );
              }
              if (Math.abs(+d.data.value) >= 1000) {
                return (
                  (+d.data.value / 1000).toFixed().replace(/\.0$/, "") + " K"
                );
              }
              return d.data.value;
            },
          },

          data: newData.map(
            (a: { add_points: any; label: any; value: any }) => {
              return {
                value: a.add_points,
                itemStyle: {
                  color: this.getFIGraphColor(a),
                },
              };
            }
          ),
        },
      ],
    };
    return option;
  }
  getFIGraphColor(key: any) {
    if (
      key.label === "Pre-Assortment" ||
      key.label === "Pre_assortment_total"
    ) {
      return "#C7972A";
    }
    if (
      key.label === "Post-Assortment" ||
      key.label === "Post_assortment_total"
    ) {
      return "#F7E6AB";
    }
    if (key.value < 0) {
      return "red";
    }
    if (
      key.label !== "Post-Assortment" &&
      key.label !== "Post_assortment_total" &&
      key.label !== "Pre-Assortment" &&
      key.label !== "Pre_assortment_total"
    ) {
      return "#E5B629";
    }
    return "#EED065";
  }

  setStackChartData(
    containerName: any,
    categories: any,
    prePortfolioData: any[] = [],
    modelOutputData: any[] = [],
    postPortfolioData: any[] = []
  ) {

    const colors = [
      "#E8D2A0",
      "#ED8269",
      "#DC5F6D",
      "#985D79",
      "#4D5175",
      "#E9C699",
      "#EA766C",
      "#C46373",
      "#8B5A7A",
      "#3E5172",
      "#EAB791",
      "#E76C6E",
      "#B06478",
      "#7F577B",
      "#2B4A6B",
      "#EBA888",
      "#E6696E",
      "#A66178",
      "#75547C",
      "#194163",
      "#ED9A7B",
      "#E3646D",
      "#A06079",
      "#605278",
      "#06365A",
    ];
    let prePortfolioValue: { name: string; data: number[]; color: string }[] =
      [];
    let modelOutputValue: { name: string; data: number[]; color: string }[] =
      [];

    let postPortfolioValue: { name: string; data: number[]; color: string }[] =
      [];

    prePortfolioData.forEach((item: any, index: any) => {
      const newItem = { ...item };
      newItem.color = colors[index % colors.length];
      prePortfolioValue.push(newItem);
    });

    modelOutputData.forEach((item: any, index: any) => {
      const newItem = { ...item }; // Clone the original object
      newItem.color = colors[index % colors.length];
      modelOutputValue.push(newItem);
    });

    postPortfolioData.forEach((item: any, index: any) => {
      const newItem = { ...item }; // Clone the original object
      newItem.color = colors[index % colors.length];
      postPortfolioValue.push(newItem);
    });

    let chartData;
    if (containerName === "stack-preRecommendation") {
      chartData = prePortfolioValue;
    } else if (containerName === "model-recommendation") {
      chartData = modelOutputValue;
    } else if (containerName === "stack-postRecommendation") {
      chartData = postPortfolioValue;
    } else {
      return;
    }
    //@ts-ignore
    Highcharts.chart(containerName, {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: categories,
        labels: {
          style: {
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Open Sans",
          },
        },
        lineColor: "#EFEFEF",
        lineWidth: 1,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        labels: {
          enabled: false,
          format: "{value}%",
          style: {
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Open Sans",
          },
        },
        lineColor: "#EFEFEF",
        lineWidth: 1,
      },
      tooltip: {
        pointFormat:
          "{series.name} : <b>{point.y}</b> ({point.percentage:.0f}%)<br/>",
        shared: true,
        backgroundColor: "#000000",
        style: {
          color: "#ffffff",
        },
        formatter: function () {
          let points = "";
          points += this.key + " <br/><br/>";
          if (typeof this.y !== "undefined" && this.y !== null) {
            this.points?.forEach((each) => {
              points +=
                toTitleCase(each.series?.name) +
                " : <b>" +
                Highcharts.numberFormat(each.y!, 2, ".", ",") +
                "</b> (" +
                Highcharts.numberFormat(each.percentage, 0) +
                "%)<br/>";
            });
          }
          return points;
        },
      },
      plotOptions: {
        column: {
          stacking: "percent",
          dataLabels: {
            enabled: true,
            format: "{point.percentage:.0f}%",
            style: {
              color: "#323232",
              fontSize: "12px",
              fontFamily: "Open Sans",
              textOutline: "none",
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      series: chartData,
    });
  }

  setStackedWaterfallChartData(containerId: any, seriesData: any) {
    // @ts-ignore
    Highcharts.chart(containerId, {
      chart: {
        type: "waterfall",
      },
      title: {
        text: "",
      },
      xAxis: [
        {
          categories: seriesData.labels,
          labels: {
            style: {
              color: "#666666",
              fontSize: "14px",
              fontFamily: "Open Sans",
            },
          },
          lineColor: "#EFEFEF",
          lineWidth: 1,
        },
      ],
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        labels: {
          enabled: false,
          format: "{value}%",
          style: {
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Open Sans",
          },
        },
        gridLineWidth: 1,
        gridLineColor: "#EFEFEF",
      },
      plotOptions: {
        series: {
          stacking: "normal",
          borderWidth: 0,
          borderRadius: 0,
        },
      },
      tooltip: {
        backgroundColor: "#000000",
        style: {
          color: "#ffffff",
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      series: seriesData.data,
    });
  }

  setGroupedBarChartData(containerName: any, labels: any, barData: any) {
    let updatedLabels = labels.map((label: any) => this.toTitleCase(label));

    //@ts-ignore
    Highcharts.chart(containerName, {
      //@ts-ignore
      chart: {
        type: "column",
      },
      title: {
        text: "",
        align: "left",
      },
      xAxis: {
        categories: updatedLabels,
        crosshair: false,

        labels: {
          style: {
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Open Sans",
          },
        },
        lineColor: "#EFEFEF",
        lineWidth: 1,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        labels: {
          style: {
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Open Sans",
          },
        },
        lineColor: "#EFEFEF",
        lineWidth: 1,
      },
      tooltip: {
        valueSuffix: "",
        backgroundColor: "#000000",
        style: {
          color: "#ffffff",
        },
        formatter: function () {
          let tooltip = "";
          if (typeof this.y !== "undefined" && this.y !== null) {
            tooltip +=
              "<b>" +
              this.series.name +
              "</b>: " +
              Highcharts.numberFormat(this.y, 2, ".", ",");
          }
          return tooltip;
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: barData,
    });
  }

  setWaterfallChartData(
    containerName: string,
    data?: any,
    data1?: any,
    data2?: any,
    topCount: number = 0,
    bottomCount: number = 0
  ) {
    let chartData;
    let minValue;
    let plotBands: any[] = [];
    let plotLines: any[] = [];

    const calculateMinValue = (
      data: any,
      preName: string,
      upliftName: string
    ) => {
      const pre = data?.find((d: any) => d.name === preName)?.y;
      const negativeUplift = data?.find((d: any) => d.name === upliftName)?.y;
      const percentageNegativeUplift = (negativeUplift / pre) * 100;
      const adjustedPercentage = percentageNegativeUplift + 10;
      const finalValue = (adjustedPercentage / 100) * pre;
      return finalValue * 10;
    };

    const calculateSecondMaxYValue = (data2: any) => {
      const yValues = data2.map((item: any) => item.y);
      const uniqueSortedYValues = [...new Set(yValues)].sort(
        (a: any, b: any) => b - a
      );
      return uniqueSortedYValues[1];
    };

    if (
      containerName === "macoUpliftChart" ||
      containerName.includes("macoUpliftChart")
    ) {
      chartData = data;
      if (data) {
        minValue = calculateMinValue(data, "Pre", "Negative Uplift");
      }
    } else if (
      containerName === "volumeUpliftChart" ||
      containerName.includes("volumeUpliftChart")
    ) {
      chartData = data1;
      if (data1) {
        minValue = calculateMinValue(data1, "Pre", "Volume Delisted");
      }
    } else if (containerName === "macoAnalysisChart") {
      chartData = data2;
      if (data2) {
        minValue = calculateSecondMaxYValue(data2);
      }

      // Defined plotBands for macoAnalysisChart
      plotBands = [];
      plotLines = [];

      // Add the top plot band if topCount is greater than 0
      if (topCount > 0) {
        plotBands.push({
          from: 0.5,
          to: topCount + 0.5,
          color: "rgba(76, 182, 76, 0.04)",
          zIndex: 2,
          label: {
            text: "Top Positive Uplift",
            align: "center",
            verticalAlign: "top",
            y: 0,
            style: {
              color: "#666666",
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: "Open Sans",
            },
          },
        });

        plotLines.push(
          {
            value: 0.5,
            color: "#e9e9e9",
            width: 2,
            dashStyle: "Dash",
            zIndex: 3,
          },
          {
            value: topCount + 0.5,
            color: "#e9e9e9",
            width: 2,
            dashStyle: "Dash",
            zIndex: 3,
          }
        );
      }

      if (bottomCount > 0) {
        plotBands.push({
          from: topCount + 0.65, // Adjust the starting point to be after the topCount band
          to: topCount + bottomCount + 0.5,
          color: "rgba(245, 50, 92, 0.04)",
          zIndex: 2,
          label: {
            text: "Bottom Negative Uplift",
            align: "center",
            verticalAlign: "top",
            x: 0,
            y: 0, // Adjust this value as needed
            style: {
              color: "#666666",
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: "Open Sans",
            },
          },
        });

        plotLines.push(
          {
            value: topCount + 0.65,
            color: "#e9e9e9",
            width: 2,
            dashStyle: "Dash",
            zIndex: 3,
          },
          {
            value: topCount + bottomCount + 0.5,
            color: "#e9e9e9",
            width: 2,
            dashStyle: "Dash",
            zIndex: 3,
          }
        );
      }
    } else if (containerName.includes("revenueUpliftChart")) {
      chartData = data2;
      if (data2) {
        minValue = calculateMinValue(data2, "Pre", "Negative Uplift");
      }
    } else {
      return;
    }

    //@ts-ignore
    Highcharts.chart(containerName, {
      chart: {
        type: "waterfall",
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "category",
        labels: {
          style: {
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Open Sans",
            whiteSpace: "normal", // Allow wrapping
          },
          rotation: 0,
          formatter: (function (containerName) {
            return function () {
              if (!containerName.includes("macoAnalysisChart")) {
                const label = this.value as string;
                return label.replace(/ /g, "<br>"); // Split after spaces
              }
              return this.value; // Always return a value
            };
          })(containerName),
        },
        lineColor: "#EFEFEF",
        lineWidth: 1,
        plotBands: plotBands,
        plotLines: plotLines, // Add plotBands to xAxis
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          style: {
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Open Sans",
          },
          formatter: function () {
            if (typeof this.value === "number") {
              return this.value / 1000000 + "M";
            }
            return this.value;
          },
        },
        lineColor: "#EFEFEF",
        lineWidth: 1,
        min: minValue,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        outside: true, // Render tooltips outside the chart area
        formatter: function () {
          if (
            typeof this.point !== "undefined" &&
            typeof this.point.y === "number"
          ) {
            const colorDot =
              '<span style="color: ' + this.point.color + '">&#9679;</span>';
            return (
              "<b>" +
              colorDot +
              " " +
              this.point.name +
              " : " +
              Highcharts.numberFormat(this.point.y, 0, "", ",") +
              "</b>"
            );
          }
          return "";
        },
        backgroundColor: "#000000",
        style: {
          color: "#ffffff",
          pointerEvents: "none",
        },
      },
      series: [
        {
          data: chartData,
          borderWidth: 0,
          borderRadius: 0,
          dataLabels: {
            enabled: true,
            align: "center",
            verticalAlign: "top",
            y: -20, // Adjust this value as needed to position the label above the bar
            formatter: function (this: any) {
              if (
                typeof this.y === "number" &&
                !containerName.includes("journeyChart")
              ) {
                const absValue = Math.abs(this.y); // Get the absolute value for comparison
                if (absValue >= 1000000) {
                  return Highcharts.numberFormat(this.y / 1000000, 1) + " M";
                } else if (absValue >= 1000) {
                  return Highcharts.numberFormat(this.y / 1000, 1) + " K";
                }
              }
              return this.y.toFixed(1);
            },
            style: {
              color: "#000000", // Change the color to black for better visibility
              fontSize: "12px",
              fontFamily: "Open Sans",
              fontWeight: 900, // Use a numeric value for font weight
              textOutline: "none",
            },
          },
        },
      ],
    });
  }

  setBarChartData(containerName: any, demandTransferData: any) {
    //@ts-ignore
    Highcharts.chart(containerName, {
      //@ts-ignore
      chart: {
        type: "column",
      },
      title: {
        text: "",
        align: "left",
      },
      xAxis: {
        categories: demandTransferData.labels,
        crosshair: false,
        labels: {
          style: {
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Open Sans",
          },
        },
        lineColor: "#EFEFEF",
        lineWidth: 1,
      },
      yAxis: {
        min: 0,
        max: demandTransferData?.maxYaxisValue,
        title: {
          text: "",
        },
        labels: {
          style: {
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Open Sans",
          },
        },
        gridLineWidth: 1,
        gridLineColor: "#EFEFEF",
      },
      tooltip: {
        valueSuffix: "",
        backgroundColor: "#000000",
        style: {
          color: "#ffffff",
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.05,
          groupPadding: 0,
          borderWidth: 0,
          grouping: false,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      series: demandTransferData.datasets,
    });
  }

  getJourneyChartData(data: any, container: any) {
    //@ts-ignore
    Highcharts.chart(container, {
      //@ts-ignore
      chart: {
        type: "waterfall",
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "category",
        labels: {
          style: {
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Open Sans",
            whiteSpace: "normal",
          },
        },
        rotation: 0,
        lineColor: "#EFEFEF",
        lineWidth: 1,
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          style: {
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Open Sans",
          },
        },
        lineColor: "#EFEFEF",
        lineWidth: 1,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          if (
            typeof this.point !== "undefined" &&
            typeof this.point.y === "number"
          ) {
            const colorDot =
              '<span style="color: ' + this.point.color + '">&#9679;</span>';
            return (
              "<b>" +
              colorDot +
              " " +
              this.point.name +
              " : " +
              Highcharts.numberFormat(this.point.y, 0, "", ",") +
              "</b>"
            );
          }
          return "";
        },
        backgroundColor: "#000000",
        style: {
          color: "#ffffff",
        },
      },

      series: [
        {
          data: data,
          borderWidth: 0,
          borderRadius: 0,
          dataLabels: {
            enabled: true,
            align: "center",
            verticalAlign: "top",
            y: -20, // Adjust this value as needed to position the label above the bar
            style: {
              color: "#000000", // Change the color to black for better visibility
              fontSize: "12px",
              fontFamily: "Open Sans",
              fontWeight: 900, // Use a numeric value for font weight
              textOutline: "none",
            },
          },
        },
      ],
    });
  }

  setTailAnalysisChartData(
    containerName: string,
    firstTailAnalysisChart: any[],
    secondTailAnalysisChart: any[],
    thirdTailAnalysisChart: any[],
    fourthTailAnalysisChart: any[]
  ) {
    let chartData;
    let brands;

    if (containerName === "first-tail") {
      brands = firstTailAnalysisChart[0];
      chartData = firstTailAnalysisChart[1];
    } else if (containerName === "second-tail") {
      brands = secondTailAnalysisChart[0];
      chartData = secondTailAnalysisChart[1];
    } else if (containerName === "third-tail") {
      brands = thirdTailAnalysisChart[0];
      chartData = thirdTailAnalysisChart[1];
    } else if (containerName === "fourth-tail") {
      brands = fourthTailAnalysisChart[0];
      chartData = fourthTailAnalysisChart[1];
    }
    let entries = firstTailAnalysisChart[0].length;
    let baseHeight = 440; // Base height for 10 entries
    let chartHeight = Math.ceil(entries / 10) * baseHeight;  // Scale the height as a multiple of 480

    //@ts-ignore
    Highcharts.chart(containerName, {
      chart: {
        type: "bar",
        height: chartHeight
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: brands,
        title: {
          text: "",
        },
        labels: {
          style: {
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Open Sans",
          },
        },
        lineColor: "#EFEFEF",
        lineWidth: 1,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
          align: "high",
        },
        labels: {
          enabled: false,
          style: {
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Open Sans",
          },
        },
        gridLineWidth: 1,
        gridLineColor: "#EFEFEF",
        reversedStacks: false
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
        shared: true,
        backgroundColor: "#000000",
        style: {
          color: "#ffffff",
        },
        formatter: function () {
          let points = "";
          points += this.key + " <br/><br/>";
          if (typeof this.y !== "undefined" && this.y !== null) {
            this.points?.forEach((each) => {
              const formattedValue = Number.isInteger(each.y)
                ? Highcharts.numberFormat(each.y!, 0, ".", ",")
                : Highcharts.numberFormat(each.y!, 0, ".", ",");

              const formattedPercentage = Number.isInteger(each.percentage)
                ? Highcharts.numberFormat(each.percentage, 0)
                : Highcharts.numberFormat(each.percentage, 2);

              points +=
                toTitleCase(each.series?.name) +
                " : <b>" +
                formattedValue +
                "</b> (" +
                formattedPercentage +
                "%)<br/>";
            });
          }
          return points;
        },
      },
      plotOptions: {
        bar: {
          stacking: "percent",
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (this.y === 0) {
                return null;
              }
            
              // Check if the value is an integer
              if (Number.isInteger(this.percentage)) {
                return `${this.percentage}%`;
              }
            
              // If the percentage is very small, show two decimal places
              if (this.percentage > 0 && this.percentage < 0.1) {
                return `${this.percentage.toFixed(2)}%`;
              }
            
              // For other cases, show one decimal place without rounding up excessively
              const truncatedPercentage = Math.floor(this.percentage * 10) / 10;
              return `${truncatedPercentage}%`;
            },
            
            
            style: {
              color: "#000000",
              fontSize: "14px",
              fontFamily: "Open Sans",
              textOutline: "none",
            },
          },
        },
      },
      series: chartData,
    });
  }
}
